import { ChangeEvent, useRef, useState } from 'react'
import { SelectData } from '../../../common/filter/models'
import TicketConfirmedFilter from '../../../common/filter/TicketConfirmedFilter'

const ActiveTicketsFilter = ({
  initialFilter,
  updateFilters,
  callback,
}: {
  initialFilter: any
  updateFilters: (key: any) => void
  callback: (page: number, param2?: boolean | undefined) => void
}) => {
  const searchMerchantRef = useRef<HTMLInputElement>(null)
  const searchIdRef = useRef<HTMLInputElement>(null)

  const onChangeSelect = (selectData: SelectData) => {
    updateFilters((prevState: any) => ({
      ...prevState,
      euConfirmed: selectData.value === 'Confirmed',
    }))
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query: any = {}

    if (e.target.name === 'merchantId') {
      query.transactionId = e.target.value
    } else if (e.target.name === 'ticketId') {
      query.ticketToken = e.target.value
    }

    if (e.target.value === '') {
      if (e.target.name === 'merchantId') {
        updateFilters((prev: any) => {
          const copyFilters = { ...prev }
          delete copyFilters.transactionId
          return copyFilters
        })
      } else if (e.target.name === 'ticketId') {
        updateFilters((prev: any) => {
          const copyFilters = { ...prev }
          delete copyFilters.ticketToken
          return copyFilters
        })
      }
    } else {
      updateFilters({
        ...initialFilter,
        ...query,
      })
    }
  }

  return (
    <div className='row'>
      <div className='col-lg-5'>
        <div className='row align-items-md-center mb-5'>
          <TicketConfirmedFilter
            changeSelect={onChangeSelect}
            value={initialFilter.euConfirmed ? 'Confirmed' : 'Pending'}
          />
        </div>

        <div className='position-relative  me-md-2'>
          <div className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 start-0 translate-middle ms-5'>
            <i className='fa fa-search'></i>
          </div>
          <input
            ref={searchMerchantRef}
            type='text'
            name='merchantId'
            className='form-control form-control-solid ps-10 pt-3 pb-2'
            onChange={onSearchChange}
            placeholder='Поиск по Мерчант ID": xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
          />
        </div>

        <div className='mt-5'>
          <div className='position-relative '>
            <span className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute pt-4 ms-3'>
              <i className='fa fa-search'></i>
            </span>
            <input
              ref={searchIdRef}
              type='text'
              name='ticketId'
              className='form-control form-control-solid ps-10 pt-3 pb-2'
              onChange={onSearchChange}
              placeholder='Поиск по ID": xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
            />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-sm-row flex-lg-column flex-xxl-row col-lg-4 gap-4 mt-4 mt-lg-0 align-items-md-start'>
        <button className='btn btn-success' onClick={() => callback(1)}>
          Фильтровать
        </button>
        <button
          className='btn btn-primary'
          onClick={() => {
            if (searchMerchantRef.current) {
              searchMerchantRef.current.value = ''
            }
            if (searchIdRef.current) {
              searchIdRef.current.value = ''
            }
            callback(1, true)
          }}
        >
          Сбросить фильтр
        </button>
      </div>
    </div>
  )
}

export default ActiveTicketsFilter
