import { ROLE } from '../app/modules/auth'
import { env } from '../env'
import { IRequisite } from '../types'
import { ERequisiteEvent, ETicketStatus } from '../types/enums'

export const API_URL = env.REACT_APP_API_URL

export const STATUS_COLOR = {
  ['ACTIVE']: 'info',
  ['IN_PROGRESS']: 'primary',
  ['COMPLETED']: 'success',
  ['DECLINED']: 'danger',
  ['EXPIRED']: 'warning',
}

export const ROLE_NAME: { [key: string]: string } = {
  [ROLE.ROLE_ADMIN]: 'Админ',
  [ROLE.ROLE_MANAGER]: 'Менеджер',
  [ROLE.ROLE_SENIOR_CASHIER]: 'Старший кассир',
  [ROLE.ROLE_CASHIER]: 'Кассир',
  [ROLE.ROLE_SENIOR_OPERATOR]: 'Старший оператор',
  [ROLE.ROLE_OPERATOR]: 'Оператор',
}

export const REQUISITE_EVENT_NAME: { [key: string]: string } = {
  [ERequisiteEvent.CANCEL_RESERVATION]: 'Отмена резервирования',
  [ERequisiteEvent.CREATE_RESERVATION]: 'Начало резервирования',
  [ERequisiteEvent.CREATE]: 'Создание реквизита',
  [ERequisiteEvent.ENABLE_RESERVATION]: 'Включение резервирования',
  [ERequisiteEvent.DISABLE_RESERVATION]: 'Отключение резервирования',
  [ERequisiteEvent.COMPLETE_RESERVATION]: 'Завершение резервирования',
  [ERequisiteEvent.AUTO_CANCEL_RESERVATION]: 'Авто-отмена резервирования',
  [ERequisiteEvent.AUTO_COMPLETE_RESERVATION]: 'Авто-завершение резервирования',
  [ERequisiteEvent.HIDDEN]: 'Скрытие реквизита',
}

export const DEFAULT_REQUISITE_LIST: Array<IRequisite> = []

export const PAYMENT_DIRRECTION_OPTIONS = [
  {
    label: 'Пополнения',
    value: 'PAYMENT',
  },
  {
    label: 'Выплаты',
    value: 'PAYOUT',
  },
]

export const PAYMENT_METHODS_OPTIONS = [
  {
    label: 'MC/VISA UAH',
    value: 'MC/VISA UAH',
  },
  {
    label: 'PRIVAT24 P2P UAH',
    value: 'PRIVAT24 P2P UAH',
  },
  {
    label: 'MC/VISA/MIR P2P RUB',
    value: 'MC/VISA/MIR P2P RUB',
  },
  {
    label: 'SBP P2P RUB',
    value: 'SBP P2P RUB',
  },
  {
    label: 'TRANS SBP P2P RUB',
    value: 'TRANS SBP P2P RUB',
  },
  {
    label: 'A2A RUB',
    value: 'A2A RUB',
  },
  {
    label: 'CRYPTO',
    value: 'CRYPTO',
  },
]
