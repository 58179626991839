import { useIntl } from 'react-intl'

import { IReportTicket } from '../ReportsPage.types'
import { convertUTCtoMSK, formatSum } from '../../../../utils'
import { ROLE, useAuth } from '../../auth'
import { IsGranted } from '../../auth/PrivateRoute'

const ReportTicketItem = ({
  ticket,
  ticketType,
}: {
  ticket: IReportTicket
  ticketType?: string
}) => {
  const intl = useIntl()
  const { currentUser } = useAuth()

  return (
    <tr>
      <td>{ticket.token}</td>
      <td>{ticket.platformTransactionId || '-'}</td>
      <td>{ticket.createdDate ? convertUTCtoMSK(ticket.createdDate) : ''}</td>
      <td>{ticket.completedDate ? convertUTCtoMSK(ticket.completedDate) : ''}</td>
      {ticketType === 'payment' && (
        <td>
          <span className='badge'>{ticket.paymentMethod ? ticket.paymentMethod : '-'}</span>
        </td>
      )}
      <td>{ticket.amount ? formatSum(ticket.amount) : '-'}</td>
      {ticketType === 'payment' &&
        IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER], currentUser) && (
          <td>{ticket.smsText ? ticket.smsText : 'смс не поступило'}</td>
        )}
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && <td>{ticket.userId}</td>}
      <td>{ticket.externalUserId}</td>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR], currentUser) && (
        <>
          <td>{ticket.merchant}</td>
          <td>{ticket.cashbox}</td>
        </>
      )}
      <td>{intl.formatMessage({ id: `ticket.status.${ticket.status}` })}</td>
      {ticketType === 'payout' &&
        IsGranted(
          [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
          currentUser
        ) && <td className='w-150px'>{ticket.commission}</td>}
    </tr>
  )
}

export default ReportTicketItem
