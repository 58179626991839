import { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { convertToUTCByMSK, formatSum } from '../../../../utils'
import { ReportBlockItem, ReportBlockItems } from '../../payment-tickets/core/_models'
import ReportTicketsFilter from './ReportTicketsFilter'
import { ReportBlock } from '../../payment-tickets/components/ReportBlock'
import { reportTicketsAPI } from '../../payment-tickets/core/_requests'
import ReportTicketItem from './ReportTicketItem'
import { Table } from '../../../../components/Table'
import { TableHead } from '../../../../components/Table/TableHead'
import { TableBody } from '../../../../components/Table/TableBody'
import { ROLE, useAuth } from '../../auth'
import { IReportTicketListObj, prepareTicketsToObject } from '../utils/prepareTicketsToObject'
import { STATUS_COLOR } from '../../../../constants'
import { getReportTicketsList } from '../api'
import { TReportType } from '../ReportsPage.types'
import { IsGranted } from '../../auth/PrivateRoute'
import { getDefaultDateRange } from '../../../../utils/getDefaultDateRange'

const perPage = 20

const accountBreadCrumbsPayment: Array<PageLink> = [
  {
    title: 'Отчёт по пополнениям',
    path: '/reports/payment',
    isSeparator: false,
    isActive: true,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
  {
    title: 'Отчёт по выплатам',
    path: '/reports/payout',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
]

const accountBreadCrumbsPayout: Array<PageLink> = [
  {
    title: 'Отчёт по пополнениям',
    path: '/reports/payment',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
  {
    title: 'Отчёт по выплатам',
    path: '/reports/payout',
    isSeparator: false,
    isActive: true,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
]

const ReportTicketsPage = ({ typeReport }: { typeReport: TReportType }) => {
  const { currentUser } = useAuth()
  const [tickets, setTickets] = useState<IReportTicketListObj>({})
  const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultDateRange()
  const [filters, setFilters] = useState<any>({
    dateStart: defaultStartDate,
    dateEnd: defaultEndDate,
    merchantTokenList: [],
    cashBoxIdList: [],
    paymentAdapterIdList: [],
    paymentMethods: [],
    page: 1,
    withoutPaymentMethod: false,
    perPage: perPage,
    ...(typeReport === 'payment' && {
      paymentMethods: [],
    }),
  })
  const [countPages, setCountPages] = useState(0)
  const [blocks, setBlocks] = useState<ReportBlockItems>({ [new Date().getTime()]: [] })
  const [isLoading, setIsLoading] = useState(false)
  const firstRender = useRef(true)

  const updateReportBlocksAndTicketList = async (
    exportXslx?: boolean,
    reset?: boolean,
    toFirstPage?: boolean
  ) => {
    if (exportXslx) {
      await updateReportBlocks(true)
    } else {
      updateReportBlocks(false, reset)
    }

    if (!exportXslx) {
      if (
        IsGranted(
          [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_SENIOR_OPERATOR],
          currentUser
        )
      ) {
        updateTicketList(toFirstPage, reset)
      }
    }
  }

  const updateReportBlocks = async (exportXslx?: boolean, reset?: boolean) => {
    const { dateStart, dateEnd, page, perPage, ...rest } = filters
    let query: any = {}

    if (reset) {
      query = {
        dateStart: convertToUTCByMSK(defaultStartDate),
        dateEnd: convertToUTCByMSK(defaultEndDate),
        merchantTokenList: [],
        cashBoxIdList: [],
        paymentAdapterIdList: [],
        withoutPaymentMethod: false,
        ticketType: typeReport,
        ...(typeReport === 'payment' && {
          paymentMethods: [],
        }),
      }

      setFilters({
        ...query,
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
      })
    } else {
      query = {
        ...rest,
        ticketType: typeReport,
        dateStart: convertToUTCByMSK(dateStart),
        dateEnd: convertToUTCByMSK(dateEnd),
      }
    }
    if (filters.withoutPaymentMethod) {
      query.paymentMethods = []
    }

    let response = await reportTicketsAPI(query, exportXslx)

    if (!response.data) {
      return false
    }

    if (exportXslx && response.data instanceof Blob) {
      fileDownload(
        response.data,
        `${typeReport}-history-${format(new Date(query.dateStart), 'dd-MM-yyyy_HH-mm')}__${format(
          new Date(query.dateEnd),
          'dd-MM-yyyy_HH-mm'
        )}.xlsx`
      )

      return true
    }

    if (response.data.success && response.data.data) {
      setBlocks({
        [new Date().getTime()]: response.data.data,
      })
    }
  }

  const updateTicketList = async (toFirstPage: boolean = true, reset?: boolean) => {
    const { dateStart, dateEnd, ...rest } = filters
    let query: any = {}

    if (reset) {
      query = {
        dateStart: convertToUTCByMSK(defaultStartDate),
        dateEnd: convertToUTCByMSK(defaultEndDate),
        merchantTokenList: [],
        cashBoxIdList: [],
        paymentAdapterIdList: [],
        page: 1,
        perPage: 20,
        withoutPaymentMethod: false,
        ...(typeReport === 'payment' && {
          paymentMethods: [],
        }),
      }

      setFilters({
        ...query,
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
      })
    } else {
      query = {
        ...rest,
        page: rest.page,
        dateStart: dateStart,
        dateEnd: dateEnd,
      }
      setFilters(query)
    }

    if (filters.withoutPaymentMethod) {
      query.paymentMethods = []
    }

    setIsLoading(true)

    if (toFirstPage) {
      query.page = 1
    }

    const { data: response } = await getReportTicketsList(query, typeReport)

    setIsLoading(false)

    if (!response.data) {
      return false
    }

    if (response.success && response.data) {
      setTickets(prepareTicketsToObject(response.data))
      setCountPages(Math.ceil(response.totalRows / perPage))
    }
  }

  useEffect(() => {
    updateReportBlocksAndTicketList()
  }, [typeReport])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      updateTicketList(false)
    }
  }, [filters.page])

  const blocksRender = Object.values(blocks)[0].map((statusData: ReportBlockItem, index: number) =>
    ['COMPLETED', 'DECLINED', 'EXPIRED'].indexOf(statusData.status) !== -1 ? (
      <div className='col-xl-4' key={index}>
        <ReportBlock
          className='card-xl-stretch mb-xl-8'
          color={STATUS_COLOR[statusData.status]}
          status={statusData.status}
          sum={formatSum(statusData.sum)}
          count={statusData.ticketsCount}
          commission={
            typeReport === 'payout' && statusData.status === 'COMPLETED'
              ? statusData.commission_sum
              : null
          }
          key={index}
        />
      </div>
    ) : (
      ''
    )
  )

  const ticketsRender = Object.keys(tickets).map((key) => (
    <ReportTicketItem ticket={tickets[key]} key={key} ticketType={typeReport} />
  ))

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle
          breadcrumbs={
            typeReport === 'payment' ? accountBreadCrumbsPayment : accountBreadCrumbsPayout
          }
        >
          Отчёт
        </PageTitle>
        <div className='card-body border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Отчёт по {typeReport === 'payment' ? 'пополнениям' : 'выплатам'}
            </span>
          </h3>
        </div>
      </div>
      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <ReportTicketsFilter
            initialFilter={filters}
            updateFilters={setFilters}
            callback={(key?: boolean, reset?: boolean, toFirstPage?: true) =>
              updateReportBlocksAndTicketList(key, reset, true)
            }
            typeReport={typeReport}
          />
        </div>
      </div>

      <div className='card mb-7'>
        <div className='row g-5 g-xl-8 pt-5 ps-5'>{blocksRender}</div>
      </div>

      {IsGranted(
        [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_SENIOR_OPERATOR],
        currentUser
      ) ? (
        <>
          <div className='card'>
            <div className='card-body py-3'>
              <Table>
                <TableHead>
                  <tr>
                    <th className='min-w-150px'>ID</th>
                    <th className='min-w-120px'>ID транзакции платформы</th>
                    <th className='min-w-140px'>Дата создания</th>
                    <th className='min-w-140px'>Дата закрытия</th>
                    {typeReport === 'payment' && <th className='min-w-140px'>Платежный метод</th>}
                    <th className='min-w-100px'>
                      Сумма {typeReport === 'payment' ? 'пополнения' : 'выплаты'}
                    </th>
                    {typeReport === 'payment' &&
                      IsGranted(
                        [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER],
                        currentUser
                      ) && <th className='w-150px'>SMS</th>}
                    {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
                      <th className='min-w-120px'>User id</th>
                    )}
                    <th className='min-w-140px'>External user id</th>
                    {IsGranted(
                      [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR],
                      currentUser
                    ) && (
                      <>
                        <th className='min-w-120px'>Merchant</th>
                        <th className='min-w-120px'>Касса</th>
                      </>
                    )}
                    <th className='min-w-120px'>Статус</th>
                    {typeReport === 'payout' &&
                      IsGranted(
                        [
                          ROLE.ROLE_ADMIN,
                          ROLE.ROLE_MANAGER,
                          ROLE.ROLE_CASHIER,
                          ROLE.ROLE_SENIOR_CASHIER,
                        ],
                        currentUser
                      ) && <th className='w-150px'>Комиссия</th>}
                  </tr>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={7}>Идёт загрузка...</td>
                    </tr>
                  ) : (
                    ticketsRender
                  )}
                </TableBody>
              </Table>
            </div>
          </div>

          <ReactPaginate
            activeClassName={'active-pagination '}
            breakClassName={'item-pagination break-me-pagination '}
            containerClassName={'pagination-pagination'}
            disabledClassName={'disabled-page-pagination'}
            nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
            pageClassName={'item-pagination pagination-page-pagination '}
            previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
            breakLabel='...'
            nextLabel=''
            onPageChange={(pageNumber) => {
              setFilters({
                ...filters,
                page: pageNumber.selected + 1,
              })
            }}
            forcePage={filters?.page - 1}
            pageRangeDisplayed={5}
            pageCount={countPages || 1}
            previousLabel=''
            renderOnZeroPageCount={null}
          />
        </>
      ) : (
        'Роль юзера не имеет соответствующих прав для просмотра детального отчета.'
      )}
    </div>
  )
}

export default ReportTicketsPage
